<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES CLIENTS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Status de Compte</v-card-title>
                        <v-card-text>
                            <v-select :items="list_status" v-model="selectstatus" dense outlined label="Status de Compte">
                            </v-select>
                            
                            <v-textarea v-model="commentaire" label="Commentaire" outlined></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog2" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">{{ detail_Formulaire.code }} {{ detail_Formulaire.email }}</v-card-title>
                        <h3 class="text-center">{{ detail_Formulaire.message }}</h3>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog2=false">Annuller</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.verified="{ item }">
            <v-chip :color="item.verified ==1?'green':'red'" dark>
                {{ item.verified ==1?'Confirmer':'Non confirmer' }}
            </v-chip>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip :color="item.status ==1?'green':'red'" dark>
                {{ item.status ==1?'Valider':'Non valider' }}
            </v-chip>

        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-eye
                </v-icon>
            </v-btn>

        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

    
<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Clients",

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Clients'
    },

    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '4%'

            },
            {
                text: "status",
                value: "status",
                divider: true,
                width: '50px'
            },
            {
                text: "key",
                align: "start",
                sortable: false,
                value: "key",
                divider: true,
                width: '5%'
            },
            {
                text: "NOM",
                align: "start",
                sortable: false,
                value: "personnel.noms",
                divider: true,
                width: '150px'
            },
            {
                text: "PRENOM",
                align: "start",
                sortable: false,
                value: "personnel.prenoms",
                divider: true,
                width: '150px'
            },

            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "email",
                divider: true,
                width: '150px'
            },
            {
                text: "SOLDE",
                align: "start",
                sortable: false,
                value: "solde",
                divider: true,
                width: '150px'
            },
            {
                text: "CODE PARAINAGE",
                align: "start",
                sortable: false,
                value: "parrain.key",
                divider: true,
                width: '100px'
            },

            {
                text: "Validation par email",
                value: "verified",
                divider: true,
                width: '50px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            nom: '',
            prenom: '',
            email: '',
            code: '',
            message: '',
        },

        list: [],
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: '',
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer','En attente'],

    }),

    created() {
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('colonne', this.selecttype_recherche == 'CODE CLIENT' ? 'key' : 'email');
            fd.append('valeur', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-clients', fd);
            this.list = data;

        },

        detail: async function (item) {
            this.id=item.id;
            this.commentaire = item.observations;
            this.selectstatus = item.status == 1 ? 'Valider' : item.status == 2 ? 'Bloquer' : 'En attente';
            this.dialog = true;
        },

        save: async function () {
            let fd = new FormData();
            fd.append('id', this.id);
            fd.append('observations', this.commentaire);
            fd.append('status', this.selectstatus== 'Valider' ? 1 : 'Bloquer' ? 2 : 0);
            const data = await api.createUpdatedata('backoffice/only-clients-update-status', fd);
            if (data.status == 200) {
                this.dialog = false;
                this.fetchData();
                this.showAlert(data.message, 'success');
            }  else if(data.status == 400) {
                this.dialog = false;
                this.dialog2 = true;
                this.detail_Formulaire = {
                    email: data.data.email,
                    code: data.data.key,
                    message: data.message,
                }
            }else {
                this.dialog = false;
                this.showAlert(data.message, 'error');
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
